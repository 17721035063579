export default {
  namespaced: true,
  state: () => ({
    expand: false
  }),
  mutations: {
    setExpand(state, flag) {
      state.expand = flag
    }
  }
}
