import layout from '@/layout'
import NotFooterLayout from '@/layout/NotFooter.vue'
export const publicRoutes = [
  {
    path: '/',
    component: layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import('@/views/home/index.vue'),
        name: 'home',
        meta: {
          title: '首页'
        }
      },
      {
        path: '/price',
        component: () => import('@/views/price/index.vue'),
        name: 'price',
        meta: {
          title: '价格'
        }
      },
      {
        path: '/path',
        component: () => import('@/views/path/index.vue'),
        name: 'path',
        meta: {
          title: '学习路径'
        }
      },
      {
        path: '/shizhan',
        component: () => import('@/views/shizhan/index.vue'),
        name: 'shizhan-path',
        meta: {
          title: '大实战'
        }
      },
      {
        path: '/shizhan/introduce/:book_id',
        component: () => import('@/views/shizhan/introduce.vue'),
        name: 'shizhan-introduce',
        meta: {
          title: '大实战课程介绍'
        }
      },
      {
        path: '/path/introduce/:book_id',
        component: () => import('@/views/path/introduce.vue'),
        name: 'path-introduce',
        meta: {
          title: '学习路径课程介绍'
        }
      },
      {
        path: '/video-class',
        component: () => import('@/views/video-course/index.vue'),
        name: 'video-class',
        meta: {
          title: '视频路线'
        }
      },
      {
        path: '/video-class/introduce/:course_id',
        component: () => import('@/views/video-course/introduce.vue'),
        name: 'video-class-introduce',
        meta: {
          title: '视频课程介绍'
        }
      },
      {
        path: '/book-class',
        component: () => import('@/views/book/index.vue'),
        name: 'book-class',
        meta: {
          title: '专栏路线'
        }
      },
      {
        path: '/book-class/introduce/:book_id',
        component: () => import('@/views/book/introduce.vue'),
        name: 'book-class-introduce',
        meta: {
          title: '专栏课程介绍'
        }
      },

      {
        path: '/project',
        component: () => import('@/views/project/index.vue'),
        name: 'project',
        meta: {
          title: '项目库'
        }
      },
      {
        path: '/project/introduce/:id',
        component: () => import('@/views/project/introduce.vue'),
        name: 'project-introduce',
        meta: {
          title: '项目课程介绍'
        }
      }
    ]
  },
  {
    path: '/project',
    component: NotFooterLayout,
    children: [
      {
        path: '/project/detail/:id',
        component: () => import('@/views/project/detail.vue'),
        name: 'project-detail',
        meta: {
          title: '项目详情'
        }
      }
    ]
  },
  {
    path: '/shizhan',
    component: NotFooterLayout,
    children: [
      {
        path: '/shizhan/detail/:path_id/:book_id',
        component: () => import('@/views/shizhan/detail.vue'),
        name: 'shizhan-detail',
        meta: {
          title: '大实战详情'
        }
      }
    ]
  },
  {
    path: '/path',
    component: NotFooterLayout,
    children: [
      {
        path: '/path/detail/:path_id/:book_id',
        component: () => import('@/views/path/detail.vue'),
        name: 'path-detail',
        meta: {
          title: '体系课详情'
        }
      }
    ]
  },
  {
    path: '/book-class',
    component: NotFooterLayout,
    children: [
      {
        path: '/book-class/detail/:path_id/:book_id',
        component: () => import('@/views/book/detail.vue'),
        name: 'book-class-detail',
        meta: {
          title: '专栏课程详情'
        }
      }
    ]
  },
  {
    path: '/video-class',
    component: NotFooterLayout,
    children: [
      {
        path: '/video-class/book/detail/:id',
        component: () => import('@/views/video-course/book/detail.vue'),
        name: 'video-class-book-detail',
        meta: {
          title: '视频配套文档详情'
        }
      }
    ]
  },
  {
    path: '/video-class/video/detail/:course_id',
    component: () => import('@/views/video-course/detail.vue'),
    meta: {
      title: '视频播放'
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
    component: layout,
    children: [
      {
        path: '',
        component: () => import('@/views/404'),
        name: '404',
        meta: {
          title: '体系课'
        }
      }
    ]
  }
]
